import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Account} from './data/account';
import {AccountSearchParams} from './data/account.search.params';
import {AccountStatus} from './data/account.status';
import {AccountOverview} from './data/account.overview';
import {AccountOverviewResult} from './data/account.overview.result';
import {AntragOverviewResult} from './data/antrag.overview.result';
import {AntragSearchParams} from './data/antrag.search.params';
import {Role} from './data/role';
import {AntragRole} from './data/antrag.role';
import {UserProfile} from './data/user.profile';
import {AccountInfo} from './data/account.info';
import {BuildInfoData} from './data/build.info.data';
import {AntragWithXmlData} from "./data/antragWithXmlData";
import {AccountTypes} from "./data/account.types";
import {CityResult} from "./data/cityResult";
import {NotificationsResult} from "./data/notifications";
import {AccountAuditSearchParams} from "./data/account.audit.search.params";
import {AccountAuditOverviewResult} from "./data/account.audit.overview.result";


@Injectable({
  providedIn: 'root'
})
export class BackendService {

  baseUrl = '/BafaUserPortal/portal';
  logoutUrl = '/public/logout';
  searchEndpoint = '/antraege/search';
  pdfEndpoint = '/pdf/';
  vnDataEndpoint = "/vnDataExist/"
  loginEndpoint = '/auth';
  registerEndpoint = '/public/register/';
  ozgEndpoint = '/ozgAuthentication'
  activationEndpoint = '/public/activate/';
  requestResetEndpoint = '/public/requestReset/';
  resetPasswordEndpoint = '/public/reset/';
  csrfEndpoint = '/public/csrf';
  buildInfoEndpoint = '/public/buildInfo';
  accountEndpoint = '/accounts';
  accountAdminEndpoint = '/admin/accounts';
  accountAuditEndpoint = '/admin/accounts/audit/';
  antraegeAdminEndpoint = '/admin/antraege';
  searchAdminEndpoint = this.antraegeAdminEndpoint + '/search';
  antraegeStornoEndpoint = '/antraege/storno';
  antraegeDeleteEndpoint = '/admin/antraege/delete/';
  accountDeleteEndpoint = '/admin/accounts/delete/';
  notificationsEndpoint = '/public/notifications/';
  citiesByZipEndpoint = '/public/getCitiesByZip/';
  streetsByCity = '/public/autocomplete/streets';
  buildingsByCityAndStreet = '/public/autocomplete/buildings';
  updatePersDataEndpoint = '/antraege/persdata/update';

  constructor(private router: Router, private http: HttpClient) {
  }

  auth(): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.baseUrl + this.loginEndpoint);
  }

  register(email: string): Observable<String> {
    return this.http.post<any>(this.baseUrl + this.registerEndpoint + email , null);
  }


  createToken(email: string, password: string): string {
    // to avoid latin1 range problem (allow password to contain € for example)
    const token = window.btoa(unescape(encodeURIComponent(email + ':' + password)));
    sessionStorage.setItem('token', token);
    return token;
  }

  login(profile: UserProfile, token: string, email: string): void {
    if (profile) {
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('profile', JSON.stringify(profile));
      this.router.navigate(['/home']);
    }
  }

  logout(isLoginFailed: boolean): void {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('profile');

    if (isLoginFailed) {
      this.router.navigate(['/login']);

    } else {
      this.router.navigate(['/logout']);
    }
    this.http.post(this.baseUrl + this.logoutUrl, {}).subscribe();
  }

  setProfile(profile: UserProfile): void {
    if (profile) {
      sessionStorage.setItem('profile', JSON.stringify(profile));
    }
  }

  activateAccount(uuid: string): Observable<AccountStatus> {
    return this.http.get(this.baseUrl + this.csrfEndpoint).pipe(
      concatMap(() => this.http.put<AccountStatus>(this.baseUrl + this.activationEndpoint + uuid, null)));
  }

  requestReset(email: string, vorgangsnummer: string): Observable<boolean> {
    return this.http.get(this.baseUrl + this.csrfEndpoint).pipe(
      concatMap(() => this.http.post<boolean>(this.baseUrl + this.requestResetEndpoint + email, vorgangsnummer)));
  }

  requestGetAccount(email: string): Observable<AccountOverview> {
    return this.http.get<AccountOverview>(this.baseUrl + this.registerEndpoint + email);
  }

  resetPassword(uuid: string, newPassword: string): Observable<boolean> {
    return this.http.get(this.baseUrl + this.csrfEndpoint).pipe(
      concatMap(() => this.http.put<boolean>(this.baseUrl + this.resetPasswordEndpoint + uuid, newPassword)));
  }

  getOverview(searchParams: AntragSearchParams): Observable<AntragOverviewResult> {
    return this.http.post<AntragOverviewResult>(this.baseUrl + this.searchEndpoint, searchParams);
  }

  getOverviewByEmail(email: string, searchParams: AntragSearchParams): Observable<AntragOverviewResult> {
    return this.http.post<AntragOverviewResult>(this.baseUrl + this.searchAdminEndpoint + '/' + email, searchParams);
  }

  getAllAntraegeByEmail(email: string, role: AntragRole): Observable<AntragOverviewResult> {
    return this.http.get<AntragOverviewResult>(this.baseUrl + "/admin/antraege/getAll/" + email + "/" + role);
  }

  getOzgServiceAuthentication(type: string, vorgangsnummer: string, privatePerson: boolean): Observable<String>  {
    // @ts-ignore
    return this.http.get(this.baseUrl +"/antraege" +'/' + type + '/' + vorgangsnummer + '/' + privatePerson +this.ozgEndpoint, {responseType: 'text'});
  }

  isAuthenticated(): boolean {
    return sessionStorage.getItem('email') && sessionStorage.getItem('email') !== '';
  }

  getAuthToken(): string {
    return sessionStorage.getItem('token');
  }

  getEmail(): string {
    return sessionStorage.getItem('email');
  }

  getRole(): Role {
    return this.getProfile().accountRole;
  }

  getAccountType(): AccountTypes {
    return this.getProfile().accountType;
  }

  getFormuralTypes(): string[] {
    return this.getProfile().formularTypes;
  }

  isAlias(): boolean {
    return this.getRole() === Role.Alias;
  }

  getAntragRoles(): AntragRole[] {
    return this.getProfile().antragRoles;
  }

  getAntragRolesForEmail(email: string): Observable<AntragRole[]> {
    return this.http.get<AntragRole[]>(this.baseUrl + this.accountAdminEndpoint + '/' + email + '/antragRoles');
  }
  getProfile(): UserProfile {
    return JSON.parse(sessionStorage.getItem('profile')) as UserProfile;
  }

  updatePassword(account: Account): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl + this.accountEndpoint, account);
  }

  changeEmail(oldEmail: string, newEmail: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.accountEndpoint + '/' + oldEmail, newEmail);
  }

  getPdf(uuid: string): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(this.baseUrl + this.pdfEndpoint + uuid, {
      headers: {
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf'
      },
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  getVnLinkDataExist(type: string, vorgangsnummer: string): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get<HttpResponse<ArrayBuffer>>(this.baseUrl + this.vnDataEndpoint + type + "/" + vorgangsnummer);
  }


  getAccounts(searchParams: AccountSearchParams, eamilStatus: boolean): Observable<AccountOverviewResult> {
    return this.http.post<AccountOverviewResult>(this.baseUrl + this.accountAdminEndpoint + '/search/' + eamilStatus, searchParams);
  }

  getAccountAudit(searchParams: AccountAuditSearchParams, email: string): Observable<AccountAuditOverviewResult> {
    return this.http.post<AccountAuditOverviewResult>(`${this.baseUrl}${this.accountAuditEndpoint}${email}`, searchParams);
  }

  createAccount(email: string): Observable<AccountOverview> {
    return this.http.post<AccountOverview>(this.baseUrl + this.accountAdminEndpoint + '/' + email, null);
  }

  updateAccount(account: AccountOverview, action: string): Observable<AccountOverview> {
    return this.http.put<AccountOverview>(this.baseUrl + this.accountAdminEndpoint + '/' + action + "/" + this.getEmail(), account);
  }

  requestAdminReset(email: string, force: boolean): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.accountAdminEndpoint + '/' + email + '/reset/' + force + '/' + this.getEmail(), null);
  }

  requestAdminActivate(email: string, force: boolean): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.accountAdminEndpoint + '/' + email + '/activate/' + force + '/' + this.getEmail(), null);
  }

  requestPublicActivate(email: string, force: boolean): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.registerEndpoint  + email + '/activate/' + force, null);
  }

  requestPublicRegister(email: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.registerEndpoint + "accounts" + "/" + email, null);
  }

  updateVorgangsdaten(email: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.accountAdminEndpoint + '/' + email + '/updateVorgangsdaten/' + this.getEmail(), null);
  }

  updateVorgangsNummer(vorgangsnummer: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.antraegeAdminEndpoint + '/' + vorgangsnummer + '/updateVorgangsNummer', null);
  }

  getAntragByVorgangsnummer(vorgangsnummer: string): Observable<AntragOverviewResult> {
    return this.http.get<AntragOverviewResult>(this.baseUrl + this.antraegeAdminEndpoint + '/' + vorgangsnummer + '/vorgangsnummer');
  }

  getAntragByVorgangsnummerForSuperUser(vorgangsnummer: string, kennzeichen: string[]): Observable<AntragOverviewResult> {
    if (kennzeichen == null) {
      return this.http.get<AntragOverviewResult>(this.baseUrl + this.antraegeAdminEndpoint + '/' + vorgangsnummer + '/vorgangsnummer');
    } else {
      return this.http.get<AntragOverviewResult>(this.baseUrl + this.antraegeAdminEndpoint + '/' + vorgangsnummer + '/vorgangsnummer/' + kennzeichen);
    }
  }

  getCompleteAntragByVorgangsnummerAndType(vorgangsnummer: string, type: string): Observable<AntragWithXmlData> {
    return this.http.get<AntragWithXmlData>(this.baseUrl +  '/antraege/' + type  +'/' + vorgangsnummer + '/persdata/data');
  }

  updatePersonlicheDaten(data: AntragWithXmlData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}${this.updatePersDataEndpoint}`, data);
  }

  getEmailByVorgangsnummer(vorgangsnummer: string): Observable<AccountInfo> {
    return this.http.get<AccountInfo>(this.baseUrl + this.antraegeAdminEndpoint + '/' + vorgangsnummer + '/getEmailByVorgangsnummer' );
  }

  buildInfo(): Observable<BuildInfoData> {
    return this.http.get<BuildInfoData>(this.baseUrl + this.buildInfoEndpoint);

  }

  stornoAntraege(vorgangsnummer: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.antraegeStornoEndpoint, vorgangsnummer);
  }

  getNotifications(types: string): Observable<NotificationsResult> {
    return this.http.get<NotificationsResult>(`${this.baseUrl}${this.notificationsEndpoint}${types}`);
  }


  createOrCancelDeleteAntragRequest(vorgangsnummer: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}${this.antraegeDeleteEndpoint}${vorgangsnummer}` + "/" + this.getEmail(), null);

  }

  createOrCancelDeleteAccountRequest(email: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}${this.accountDeleteEndpoint}${email}` + "/" + this.getEmail(), null);
  }

  getCitiesByZip(zip: string): Observable<CityResult> {
    return this.http.get<CityResult>(`${this.baseUrl}${this.citiesByZipEndpoint}${zip}`);
  }

  getStreetsByZip(postalCode: string, city: string, street: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}${this.streetsByCity}`, {params: {
        postalCode: postalCode,
        city: city,
        street: street
      }});
  }

  getBuildingsByCityAndStreet(postalCode: string, city: string, street: string, houseNumber: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}${this.buildingsByCityAndStreet}`,
    {params: {
      postalCode: postalCode,
        city: city,
        street: street,
        houseNumber: houseNumber
    }});
  }
}

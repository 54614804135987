<mat-card class="mat-elevation-z0" [ngStyle]="{'max-width' : (data.deleteRequestDate || (dataSourceBevollmaechtigterResultsLength == 0 && dataSourceAntragstellerResultsLength == 0)) ? '550px' : '750px', 'overflow' : (isLoading) ? 'hidden' : ''}" fxFlex.md="65%" fxFlex.lt-md="65%">

  <!-- title -->
  <div *ngIf="!isLoading">
  <mat-card-title *ngIf="!data.deleteRequestDate && !deletionAllowed" class="text-center"> Das Konto {{data.email}} kann nicht gelöscht werden, weil nicht alle Anträge löschbar sind.</mat-card-title>
  <mat-card-title *ngIf="!data.deleteRequestDate && deletionAllowed" class="text-center"> Möchten Sie das Löschen des Kontos {{data.email}} wirklich anfordern?</mat-card-title>
  <mat-card-title *ngIf="data.deleteRequestDate" class="text-center"> Möchten Sie die Anforderung zum Löschen des Kontos {{data.email}} zurücknehmen?</mat-card-title>
  </div>
  <div class="loading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <mat-card-content class="text-left" *ngIf="!isLoading">

    <br/>
    <mat-card-subtitle *ngIf="!data.deleteRequestDate && dataSourceAntragstellerResultsLength != 0">Als Antragsteller
      {{dataSourceAntragstellerResultsLength > 1 ? 'sind' : 'ist'}} {{dataSourceAntragstellerResultsLength}}
      {{dataSourceAntragstellerResultsLength > 1 ? 'Anträge' : 'Antrag'}} unter diesem Account:</mat-card-subtitle>
    <!-- table -->
    <div class="delete-overview-table-container" *ngIf="!data.deleteRequestDate && dataSourceAntragstellerResultsLength != 0">

      <table mat-table [dataSource]="dataSourceAntragsteller" style="table-layout: fixed">

      <ng-container matColumnDef="vorgangsnummer">
        <th mat-header-cell *matHeaderCellDef  mat-header-row>Vorgangsnummer</th>
        <td mat-cell  *matCellDef="let element">{{ element.vorgangsnummer }}</td>
      </ng-container>

        <ng-container matColumnDef="kennzeichen">
          <th mat-header-cell *matHeaderCellDef mat-header-row>Verfahren</th>
          <td mat-cell *matCellDef="let element">{{ element.kennzeichenText }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-header-row>Status</th>
          <td mat-cell *matCellDef="let element">{{ getStatusTypeText(element.status).description }}</td>
        </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns() sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
      </table>
    </div>

    <br/>
    <mat-card-subtitle *ngIf="!data.deleteRequestDate && dataSourceBevollmaechtigterResultsLength != 0">Als Bevollmächtigte
      {{dataSourceBevollmaechtigterResultsLength > 1 ? 'sind' : 'ist'}}
      {{dataSourceBevollmaechtigterResultsLength}} {{dataSourceBevollmaechtigterResultsLength > 1 ? 'Anträge' : 'Antrag'}} unter diesem Account:</mat-card-subtitle>
    <!-- table -->
    <div class="delete-overview-table-container" *ngIf="!data.deleteRequestDate && dataSourceBevollmaechtigterResultsLength != 0">

      <table mat-table [dataSource]="dataSourceBevollmaechtigter" style="table-layout: fixed">

        <ng-container matColumnDef="vorgangsnummer">
          <th mat-header-cell *matHeaderCellDef  mat-header-row>Vorgangsnummer</th>
          <td mat-cell  *matCellDef="let element">{{ element.vorgangsnummer }}</td>
        </ng-container>

        <ng-container matColumnDef="kennzeichen">
          <th mat-header-cell *matHeaderCellDef mat-header-row>Verfahren</th>
          <td mat-cell *matCellDef="let element">{{ element.kennzeichenText }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-header-row>Status</th>
          <td mat-cell *matCellDef="let element">{{ getStatusTypeText(element.status).description }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns() sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
      </table>
    </div>

    <br/>
    <mat-card-subtitle *ngIf="!data.deleteRequestDate &&
    (dataSourceBevollmaechtigterResultsLength != 0 || dataSourceAntragstellerResultsLength != 0)">
      Die Anträge werden innerhalb von 24 Stunden endgültig gelöscht. Bis dahin ist das Abbrechen möglich.
    </mat-card-subtitle>

  </mat-card-content>
<mat-dialog-actions align="end" *ngIf="!isLoading">
  <button mat-raised-button (click)="close()" class="close-button">Abbrechen</button>
  <button *ngIf="!data.deleteRequestDate && deletionAllowed" mat-raised-button color="primary" (click)="confirm()">Löschung anfordern</button>
  <button *ngIf="data.deleteRequestDate" mat-raised-button color="primary" (click)="confirm()">Löschung stornieren</button>

</mat-dialog-actions>

</mat-card>


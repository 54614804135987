import {Moment} from 'moment';
import {Antrag} from "./data/antrag";
import {MatTableDataSource} from "@angular/material/table";
import {AccountOverview} from "./data/account.overview";

const KENNZEICHEN_MAP = new Map();

KENNZEICHEN_MAP.set('BEGEM-FV', 'BEG EM');
KENNZEICHEN_MAP.set('BEGEM2-FV', 'BEG EM');
KENNZEICHEN_MAP.set('BEGPT-FV', 'BEG PT');
KENNZEICHEN_MAP.set('EBN-FV', 'EBN');
KENNZEICHEN_MAP.set('EBW-FV', 'EBW');

const KENNZEICHEN_KEY_MAP = new Map();

KENNZEICHEN_KEY_MAP.set('BEG EM', 'BEGEM-FV');
KENNZEICHEN_KEY_MAP.set('BEG EM', 'BEGEM2-FV');
KENNZEICHEN_KEY_MAP.set('BEG PT', 'BEGPT-FV');
KENNZEICHEN_KEY_MAP.set('EBN', 'EBN-FV');
KENNZEICHEN_KEY_MAP.set('EBW', 'EBW-FV');

type AntragStatus = {
  key: string,
  description: string,
  tooltip: string,
  icon: string
};

const STATUS_LIST = new Array<AntragStatus>();

STATUS_LIST.push({
  key: '1',
  description: 'Eingegangen',
  tooltip: 'Antrag eingegangen',
  icon: 'mark_email_unread'
} as AntragStatus);
STATUS_LIST.push({
  key: '2',
  description: 'In Bearbeitung',
  tooltip: 'Antrag wird bearbeitet',
  icon: 'mark_email_read'
} as AntragStatus);
STATUS_LIST.push({
  key: '3',
  description: 'Genehmigt',
  tooltip: 'Zuwendungsbescheid erteilt',
  icon: 'check_circle'
} as AntragStatus);
STATUS_LIST.push({
  key: '4',
  description: 'VN eingegangen',
  tooltip: 'Verwendungsnachweis eingegangen',
  icon: 'mark_email_unread'
} as AntragStatus);
STATUS_LIST.push({
  key: '5',
  description: 'VN in Bearbeitung',
  tooltip: 'Verwendungsnachweis wird bearbeitet',
  icon: 'mark_email_read'
} as AntragStatus);
STATUS_LIST.push({
  key: '6',
  description: 'Ausgezahlt',
  tooltip: 'Förderung ausgezahlt',
  icon: 'euro_symbol'
} as AntragStatus);
STATUS_LIST.push({
  key: 'A',
  description: 'Abgeschlossen',
  tooltip: 'Antrag abgeschlossen',
  icon: 'done_all'
} as AntragStatus);
STATUS_LIST.push({key: 'B', description: 'Abgelehnt', tooltip: 'Antrag abgelehnt', icon: 'block'} as AntragStatus);
STATUS_LIST.push({
  key: 'C',
  description: 'ZWB aufgehoben',
  tooltip: 'Zuwendungsbescheid aufgehoben',
  icon: 'undo'
} as AntragStatus);
STATUS_LIST.push({key: 'D', description: 'Klageverfahren', tooltip: 'Klageverfahren', icon: 'gavel'} as AntragStatus);
STATUS_LIST.push({
  key: 'R',
  description: 'Rückforderung',
  tooltip: 'Förderung zurück gefordert',
  icon: 'undo'
} as AntragStatus);
STATUS_LIST.push({
  key: 'W',
  description: 'Widerspruch',
  tooltip: 'Widerspruch wird bearbeitet',
  icon: 'cancel'
} as AntragStatus);
STATUS_LIST.push({
  key: 'S',
  description: 'Storniert',
  tooltip: 'Storniert',
  icon: 'remove_circle_outline'
} as AntragStatus);
STATUS_LIST.push({
  key: 'X',
  description: 'Widerspruchsbescheid',
  tooltip: 'Widerspruchsbescheid erstellt',
  icon: 'announcement'
} as AntragStatus)

export const dateFormatDe = 'dd.MM.yyyy';
export const dateTimeFormatDe = dateFormatDe + ' HH:mm:ss';
export const dateTimeDeleteFormatDe = 'DD.MM.yyyy [um] HH:mm';


export const toUtcMoment = (date: Moment): Moment => {
  if (date == null) {
    return null;
  }
  return date.utc(true);
};

export const setKenzeichenAgregateTypesForAntrag = (antrags: Antrag[]): MatTableDataSource<any> => {
  const data = antrags;
  data.forEach(x => {
    x.kennzeichenText = KENNZEICHEN_MAP.get(x.kennzeichen);
  });

  return new MatTableDataSource(data);
};

export const setKenzeichenAgregateTypesForAccount = (accounts: AccountOverview[]): MatTableDataSource<any> => {
  const data = accounts;
  data.forEach(x => {
    if (x.formularTypes) {
      var kenzeichens = new Array<String>;
      x.formularTypes.forEach(
        type => {
          kenzeichens.push(' ' + KENNZEICHEN_MAP.get(type));
        }
      )
      x.formularTypesText = Array.from(new Set(kenzeichens));
    }
  });
  return new MatTableDataSource(data);
}

export const getKenzeichenAgregateTypesForUserProfile = (types: string[]): Array<any> => {
  var kenzeichens = new Array<String>;
  if (types == null) {
    return;
  }
  types.forEach(
    type => {
      kenzeichens.push(KENNZEICHEN_MAP.get(type));
    }
  )
  return Array.from(new Set(kenzeichens));
}

export const getKenzeichenAgregateKeys = (types: string[]): Array<any> => {
  var kenzeichens = new Array<String>;
  if (types == null) {
    return;
  }
  types.forEach(
    type => {
      if (type == 'BEG EM') {
        kenzeichens.push('BEGEM-FV');
        kenzeichens.push('BEGEM2-FV');
      } else {
        kenzeichens.push(KENNZEICHEN_KEY_MAP.get(type));
      }
    }
  )
  return Array.from(new Set(kenzeichens));
}

export const getStatusTypeText = (status: string): AntragStatus => {
  return STATUS_LIST.find(s => s.key === status);

}

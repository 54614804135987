<div class="flex-column-center" role="main">

  <mat-card class="flex-column-center margin-50">
    <form [formGroup]="form" action="">

      <!-- title -->
      <mat-card-title class="flex-column-center mat-h1">
        E-Mail-Adresse ändern
      </mat-card-title>

      <mat-card-subtitle class="flex-column-center">
        Ändern Sie die E-Mail-Adresse Ihres BAFA Benutzerkontos
        <strong>{{ email }}</strong>
      </mat-card-subtitle>

      <mat-card-content>

        <!-- new email address -->
        <mat-form-field class="width-100 email-field" appearance="fill">

          <mat-label>Neue E-Mail-Adresse</mat-label>
          <label>
            <input matInput required formControlName="newEmail" type="email" placeholder="email@example.com" [errorStateMatcher]="matcherNew">
          </label>
          <mat-error *ngIf="this.emailNew.error !== ''">
            <mat-icon>error</mat-icon>
            {{ this.emailNew.error }}
          </mat-error>

        </mat-form-field>

      </mat-card-content>

      <!-- buttons -->
      <mat-card-actions class="card-actions">
        <button mat-button class="button" type="button" color="primary" [routerLink]="['/home']">Zurück</button>
        <button mat-flat-button class="button" type="submit" color="primary" [disabled]="!this.form.valid || isAlias || isLoading" (click)="onClick()">
          <span *ngIf="!isLoading">E-Mail-Adresse ändern</span>
          <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
        </button>
      </mat-card-actions>

    </form>

  </mat-card>
  <mat-card *ngIf="success">
    <mat-card-content>
      <p>Die E-Mail-Adresse wurde erfolgreich geändert. Sie erhalten an die neue Adresse eine Aktivierungs-E-Mail.<br />
        Bevor die neue Adresse genutzt werden kann, muss sie über den Link aus dieser E-Mail bestätigt werden.</p>

      <p>Bis zum ersten Login mit der neuen Adresse können Sie weiterhin die alte Adresse zum Login verwenden.<br />
        Sobald Sie sich erstmalig mit der neuen Adresse angemeldet haben, ist ein Login mit der alten Adresse nicht mehr möglich.</p>
    </mat-card-content>
  </mat-card>
</div>


<mat-card class="mat-elevation-z0" style="max-width: 550px" fxFlex.md="75%" fxFlex.lt-md="100%">
  <!-- title -->
  <mat-card-title *ngIf="!data.deleteRequestDate" class="text-center"> Möchten Sie den Antrag mit der Vorgangsnummer {{data.vorgangsnummer}} wirklich löschen?</mat-card-title>
  <mat-card-title *ngIf="data.deleteRequestDate" class="text-center"> Möchten Sie das Löschen des Antrags mit der Vorgangsnummer {{data.vorgangsnummer}} stornieren?</mat-card-title>

  <mat-card-content class="text-center">
    <p *ngIf="!data.deleteRequestDate">
      Dieser Antrag wird innerhalb von 24 Stunden endgültig gelöscht. Nur bis dahin ist die Wiederherstellung möglich.
    </p>
  </mat-card-content>
<mat-dialog-actions align="end" >
  <button mat-raised-button (click)="close()" class="close-button">Abbrechen</button>
  <button *ngIf="!data.deleteRequestDate" mat-raised-button color="primary" (click)="confirm()">Löschung anfordern</button>
  <button *ngIf="data.deleteRequestDate" mat-raised-button color="primary" (click)="confirm()">Löschung stornieren</button>

</mat-dialog-actions>
</mat-card>


<div class="flex-column-center" role="main">
  <div class="flex-column-center margin-50"  style="max-width: 70% !important;">

    <div
      class="content md-padding"
      role="main"
      fxLayout
      fxLayoutAlign="center"
      fxLayout.xs="column"
      fxLayoutAlign.xs="start"
    >

      <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">

        <mat-card
          class="mat-elevation-z0"
          ngClass.gt-xs="overview-card"
          fxFlex="85%"
          fxFlex.md="85%"
          fxFlex.lt-md="85%"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="85%"
            fxLayoutGap="50px"
            fxLayout.xs="column"
            fxLayoutAlign.xs="center start"
          >
            <div fxFlex="85%" style="padding-top: 100px">
              <mat-card-title class="flex-column-left mat-h1">
                Willkommen auf dem Antragsportal des BAFA
              </mat-card-title>

              <!-- logo -->


              <mat-card-subtitle class="flex-column-center">
                <div>Dieses Portal bietet Ihnen eine Oberfläche, mit der Sie Anträge stellen und Verwendungsnachweise einreichen können. </div>
                <div> Nach der Anmeldung können Sie einen neuen Antrag stellen, den Status vorhandener Anträge überprüfen sowie Dokumente hochladen.</div>
                <div> Weiterhin bietet Ihnen das Portal die Möglichkeit, einen gestellten Antrag zu stornieren bzw. Ihre hinterlegten persönlichen Daten zu überarbeiten.</div>
              </mat-card-subtitle>
            </div>
          </div>
        </mat-card>

        <mat-card
          class="mat-elevation-z0"
          ngClass.gt-xs="overview-card"
          fxFlex="100%"
          fxFlex.md="100%"
          fxFlex.lt-md="100%"
        >
          <div class="flex-column-center" role="main">
            <mat-card class="mat-elevation-z5 fit-content"
                      style="margin-bottom: 20px !important">

              <!-- title -->
              <mat-card-title class="flex-column-center mat-h1">
                Anmelden
              </mat-card-title>

              <mat-card-subtitle class="flex-column-center">
                Verwenden Sie Ihr BAFA Benutzerkonto
              </mat-card-subtitle>

              <mat-card-content>

                <!-- email -->
                <mat-form-field class="width-100" appearance="fill">
                  <mat-label>E-Mail-Adresse</mat-label>
                  <label>
                    <input matInput required formControlName="email" type="email" placeholder="email@example.com" [errorStateMatcher]="matcher">
                  </label>
                </mat-form-field>

                <!-- password -->
                <mat-form-field class="width-100" [ngClass]="{'padding': this.success == false}" appearance="fill">

                  <mat-label>Passwort</mat-label>
                  <label>
                    <input matInput required formControlName="password" [type]="hide ? 'password' : 'text'" [errorStateMatcher]="matcher">
                  </label>
                  <mat-error *ngIf="!this.success">
                    <p>
                      → Prüfen Sie die E-Mail-Adresse.<br/>
                      → Prüfen Sie das Passwort.<br/>
                    </p>
                    <p>
                      <br>
                      Hinweis: Nach drei fehlgeschlagenen Anmelderversuchen sperren wir das Benutzerkonto temporär. Versuchen Sie es nach 5 Minuten erneut.<br/>
                    </p>
                  </mat-error>
                  <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-pressed]="hide"
                          matTooltip="Passwort im Klartext anzeigen">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>

              </mat-card-content>

              <!-- buttons -->
              <mat-card-actions class="card-actions">
                <button mat-button class="button" type="button" color="primary" [routerLink]="['/request-reset']">Probleme mit der Anmeldung?</button>
                <button mat-flat-button class="button" type="submit" color="primary">Weiter</button>
              </mat-card-actions>

              <mat-card-subtitle class="flex-column-center" *ngIf="enableRegistration()">
                <p>Sie haben noch kein Konto?<br>
                  Registrieren Sie sich <a [routerLink]="['/register']" >hier</a></p>
              </mat-card-subtitle>

              <mat-card-subtitle class="flex-column-center" *ngIf="!enableRegistration()">
                <p>Sie haben noch kein Konto?<br>
                  Stellen Sie zuerst einen Antrag unter <a href="https://www.bafa.de">www.bafa.de</a></p>
              </mat-card-subtitle>
            </mat-card>
          </div>
        </mat-card>
      </form>
    </div>
  </div>
</div>

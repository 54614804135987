<div class="content" role="main" fxLayout fxLayoutAlign="center" fxLayout.xs="column" fxLayoutAlign.xs="start">

  <mat-card class="mat-elevation-z0" ngClass.gt-xs="admin-card" fxFlex="70%" fxFlex.lg="85%" fxFlex.md="100%" fxFlex.lt-md="100%">

    <mat-grid-list cols="5" rowHeight="70px">
      <mat-grid-tile row="1" colspan="3" >
        <!-- title -->
        <div class="admin-header">
        <mat-card-title class="bafa-display-1" title="Accounts sperren und entsperren sowie Passwörter zurücksetzen.
      Diese Funktionen können nicht auf den eigenen Account angewendet werden." >Account-Verwaltung
        </mat-card-title>
        </div>
      </mat-grid-tile>
      <mat-grid-tile row="1" rowspan="2" colspan="2">
        <mat-card class="vorgangsnr-box">
          <mat-form-field appearance="standard">
            <input matInput placeholder="Vorgangsnummer" [(ngModel)]="vorgangsnummer">
          </mat-form-field>
          <!-- -->
          <button mat-raised-button color="primary" class="vorgangsnr-btn" matTooltip="Vorgang anzeigen"
                  (click)="this.openAdminOverviewVorgangsnummerDialog()">
            <mat-icon>search</mat-icon>
          </button>
        </mat-card>
      </mat-grid-tile>

    </mat-grid-list>
    <p></p>
    <mat-card-content>
      <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner></mat-spinner>
      </div>

      <!-- table actions-->
      <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="10px" fxLayout.xs="column"
           fxLayoutAlign.xs="center start">

        <!-- Email search field -->
        <mat-form-field fxFlex="30%" fxFlex.xs="100%" appearance="standard">
          <mat-label>E-Mail-Adresse</mat-label>
          <label>
            <input matInput type="text" placeholder="Suchbegriff" [(ngModel)]="filter" (keyup)="applyFilter()"/>
          </label>
          <button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="deleteFilter()">
            <mat-icon matTooltip="Filter löschen">close</mat-icon>
          </button>
          <button *ngIf="!filter" matSuffix mat-icon-button disabled>
            <mat-icon matTooltip="Email nach Suchbegriff filtern">search</mat-icon>
          </button>
        </mat-form-field>

        <!-- Rolle field -->
        <mat-form-field fxFlex="30%" fxFlex.xs="100%" appearance="standard" *ngIf="!isSuperUser">
          <mat-label>Rolle</mat-label>
          <mat-select #rolleFilterEvent (selectionChange)="selectionChangeRolle($event.value)">
            <mat-option>(Alle)</mat-option>
            <mat-option *ngFor="let rolle of roleList" value="{{rolle.key}}">{{rolle.tooltip}}</mat-option>              </mat-select>
        </mat-form-field>

        <!-- Verfahren field -->
        <mat-form-field fxFlex="30%" fxFlex.xs="100%" appearance="standard">
          <mat-label>Verfahren</mat-label>
          <mat-select #verfahrenFilterEvent (selectionChange)="selectionChangeKennzeichen($event.value)">
            <mat-option>(Alle)</mat-option>
            <mat-option *ngFor="let kennzeichen of kennzeichenTypeMap" value="{{kennzeichen.key}}">{{kennzeichen.text}}</mat-option>              </mat-select>
        </mat-form-field>

        <!-- Status filter select -->
        <mat-form-field fxFlex="30%" fxFlex.xs="100%" appearance="standard">
          <mat-label>Status</mat-label>
          <mat-select #statusFilterEvent (selectionChange)="selectionChange($event.value)">
            <mat-option>(Alle)</mat-option>
            <mat-option *ngFor="let status of statusList" value="{{status.key}}">{{status.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- refresh button -->
        <button mat-mini-fab color="primary" (click)="this.refreshData()"
                matTooltip="Tabelleninhalt aktualisieren">
          <mat-icon>refresh</mat-icon>
        </button>




        <!-- create new user -->
        <form [formGroup]="form" (ngSubmit)="onSubmitNewUser()" fxFlex="30%" fxFlex.xs="100%" class="edit-form"
              fxLayoutAlign="end center">
          <button mat-mini-fab *ngIf="!this.editMode && !isSuperUser" color="primary" (click)="this.editMode = true"
                  matTooltip="Account hinzufügen">
            <mat-icon>add</mat-icon>
          </button>

          <mat-form-field *ngIf="this.editMode" appearance="standard" class="width-100">
            <mat-label>E-Mail-Adresse</mat-label>
            <label>
              <input matInput required formControlName="newUser" type="email" placeholder="email@example.com">
            </label>
            <div fxLayout="row" matSuffix>
              <button *ngIf="this.editMode" mat-icon-button type="submit" color="primary" aria-label="Benutzer anlegen"
                      matTooltip="Benutzer anlegen">
                <mat-icon>save</mat-icon>
              </button>
              <button *ngIf="this.editMode" mat-icon-button type="button" color="accent" (click)="onCancelNewUser()"
                      aria-label="Eingabe abbrechen"
                      matTooltip="Eingabe abbrechen">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </mat-form-field>
        </form>
      </div>

      <!-- table -->

      <!--<div class="mat-elevation-z3 highlight example-container mat-elevation-z8">-->
      <div class="overview-table-container">
        <table mat-table [dataSource]="data" matSort [matSortActive]="active" matSortDisableClear [matSortDirection]="direction"
               class="mat-elevation-z3" (matSortChange)="sortingChange($event)">

          <ng-container matColumnDef="letter">
            <th mat-header-cell *matHeaderCellDef>A-Z</th>
            <td mat-cell *matCellDef="let row">
              {{ row.email.substring(0, 1).toUpperCase() }}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>E-Mail-Adresse</th>
            <td mat-cell *matCellDef="let row" class="email-cell"
                matTooltip="{{ row.email }}">{{ row.email }}</td>
          </ng-container>

          <ng-container matColumnDef="accountType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rolle</th>
            <td mat-cell *matCellDef="let row" class="status-cell">
              <a mat-icon-button disabled>
                <button (click)="this.openAdministrationDialog(row)"
                        [disabled]="actionDisabledForRow(row, 'admin') || isSuperUser" mat-icon-button
                        color="primary" aria-label="Befördern" matTooltip="Rolle ändern"
                [matTooltipDisabled]="actionDisabledForRow(row, 'admin') || isSuperUser">
                  <mat-icon>{{ role(row).icon }}</mat-icon>
                </button>
              </a>
              {{ role(row).name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="formularTypes">
            <th class="break-spaces" mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="Letzte fehlgeschlagene Anmeldung">Verfahren
            </th>
            <td class="break-spaces" mat-cell *matCellDef="let row">{{this.noAntragsByUser(row) ? 'Keine Anträge' : row?.formularTypesText}}</td>
          </ng-container>

          <ng-container matColumnDef="lastSuccessfulLogin">
            <th class="break-spaces" mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="Letzte erfolgreiche Anmeldung und Letzte fehlgeschlagene Anmeldung">Erfolgreiche Anmeldung<br>Fehlerhafte Anmeldung
            </th>
            <td class="break-spaces" mat-cell *matCellDef="let row"
                matTooltip="{{row.lastSuccessfulLogin | date : this.dateTimeFormatDe}}">{{row.lastSuccessfulLogin | date : dateTimeFormatDe}}<br>{{row.lastFailedLogin | date : dateTimeFormatDe}}</td>
          </ng-container>

          <ng-container matColumnDef="temporaryLockedUntil">
            <th class="break-spaces" mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="Konto temporär gesperrt bis">Gesperrt bis
            </th>
            <td class="break-spaces" mat-cell *matCellDef="let row"
                matTooltip="{{ row.temporaryLockedUntil | date : dateTimeFormatDe }}">{{
              row.temporaryLockedUntil | date : dateTimeFormatDe }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row" class="status-cell" matTooltip="{{ status(row).tooltip }}">
              <a mat-icon-button disabled>
                <mat-icon>{{ status(row).icon }}</mat-icon>
              </a>
              {{ status(row).name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="emailStatus">
            <th mat-header-cell *matHeaderCellDef>E-Mail-Status
              <button mat-icon-button color="primary" (click)="this.emailStatusInfo()" matTooltip="E-Mail Status">
                <mat-icon>info</mat-icon>
              </button>
            </th>
            <td mat-cell *matCellDef="let row" class="email-status-cell" matTooltip="{{ emailTooltip(row) }}">
              <a mat-icon-button disabled>
                <mat-icon [id]="row.email+'_'+emailStatus(row).key" *ngIf="emailStatus(row).key !== 'FAILED'" class="{{emailStatus(row).iconClass}} center {{emailStatus(row).color}}">{{ emailStatus(row).icon }}</mat-icon>
                <img [id]="row.email+'_'+emailStatus(row).key" *ngIf="emailStatus(row).key === 'FAILED'" style="width:28px" src="icon_failed_red.svg" class="center">
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Aktionen</th>
            <td mat-cell *matCellDef="let row" class="button-cell">

              <button *ngIf="!row.locked" (click)="this.updateAccount(row, 'lock')"
                      [disabled]="actionDisabledForRow(row, 'lock')" mat-icon-button
                      color="primary" aria-label="Sperren" matTooltip="Account sperren">
                <mat-icon>lock</mat-icon>
              </button>

              <button *ngIf="row.locked" (click)="this.updateAccount(row, 'unlock')"
                      [disabled]="actionDisabledForRow(row, 'unlock')" mat-icon-button
                      color="primary" aria-label="Entsperren" matTooltip="Account entsperren">
                <mat-icon>lock_open</mat-icon>
              </button>

              <button (click)="this.sendMail(row, 'activate')"
                      [disabled]="actionDisabledForRow(row, 'activate')"
                      mat-icon-button color="primary" aria-label="Aktivierungs-Link erneut versenden"
                      matTooltip="Aktivierungs-Link erneut versenden">
                <mat-icon>forward_to_inbox</mat-icon>
              </button>

              <button (click)="this.sendMail(row, 'reset')"
                      [disabled]="actionDisabledForRow(row, 'reset')" mat-icon-button
                      color="primary" aria-label="Passwort zurücksetzen" matTooltip="Passwort zurücksetzen">
                <mat-icon>settings_backup_restore</mat-icon>
              </button>

              <button (click)="this.updateVorgangsdaten(row)" [disabled]="actionDisabledForRow(row, 'update')" mat-icon-button
                      color="primary" aria-label="Vorgangsdaten aktualisieren" matTooltip="Vorgangsdaten aktualisieren">
                <mat-icon>update</mat-icon>
              </button>

              <button (click)="this.openAdminOverviewAccountDialog(row)" [disabled]="actionDisabledForRow(row, 'show') || noAntragsByUser(row)"
                      mat-icon-button
                      color="primary" aria-label="Zugeordnete Vorgänge anzeigen"
                      matTooltip="Zugeordnete Vorgänge anzeigen">
                <mat-icon>view_list</mat-icon>
              </button>

              <button (click)="this.openOverviewAccountAuditDialog(row)" *ngIf="!this.isSuperUser"
                      mat-icon-button
                      color="primary" aria-label="Zugeordnete Account audit"
                      matTooltip="Konto-Audit für {{row.email}}">
                <mat-icon>search</mat-icon>
              </button>

              <div style="display: inline-block !important;" class="my-custom-tooltip" matTooltip="{{resolveDeleteTooltipText(row)}}" [matTooltipDisabled]="false">
              <button (click)="openDeleteAccountDialog(row)" *ngIf="!this.isSuperUser"
                      mat-icon-button
                      color="primary"
                      disabled="{{row.accountType == 'ADMIN'}}"
                      aria-label="Löschung anfordern">
                <mat-icon class="center {{row.deleteRequestDate ? 'color_orange' : 'primary'}}">delete</mat-icon>
              </button>
              </div>
            </td>
          </ng-container>

          <!-- show this when there is no matching data -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Keine Ergebnisse zu Ihrem Suchbegriff "{{ filter }}".</td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
        <mat-paginator #paginator
                       [length]="resultsLength"
                       [pageIndex]="pageIndex"
                       [pageSizeOptions]="[10, 20, 50]"
                       (page)="paginationChange($event)"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>

<div class="flex-column-center" role="main">

  <mat-card class="flex-column-center margin-50">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">

      <mat-card-title class="flex-column-center mat-h1">
        Passwort vergessen oder Konto noch nicht aktiviert?
      </mat-card-title>

      <mat-card-subtitle class="flex-column-center margin-10">
        <div>Geben Sie die E-Mail-Adresse Ihres BAFA-Benutzerkontos ein.</div>
        Wir senden Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts bzw. zur Aktivierung Ihres Kontos.
      </mat-card-subtitle>

      <mat-card-content>

        <!-- email -->
        <mat-form-field class="width-100" appearance="fill">
          <mat-label>E-Mail-Adresse</mat-label>
          <label>
            <input matInput required formControlName="email" type="email" placeholder="email@example.com">
          </label>
        </mat-form-field>

        <!-- vorgangsnummer
        <mat-form-field class="width-100" appearance="fill">
          <mat-label>Vorgangsnummer</mat-label>
          <label>
            <input matInput required formControlName="vorgangsnummer" type="text">
          </label>
        </mat-form-field>
        -->

      </mat-card-content>

      <!-- buttons -->
      <mat-card-actions class="card-actions">
        <button mat-button class="button" type="button" color="primary" [routerLink]="['/login']">Zurück</button>
        <button mat-flat-button class="button" type="submit" color="primary" [disabled]="!this.form.valid">E-Mail anfordern</button>
      </mat-card-actions>

    </form>

  </mat-card>
</div>

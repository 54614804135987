<div class="content" role="main" fxLayout fxLayoutAlign="center" fxLayout.xs="column" fxLayoutAlign.xs="start">

  <mat-card class="mat-elevation-z0" ngClass.gt-xs="overview-card" fxFlex={{fxFlexValue}} fxFlex.md="75%" fxFlex.lt-md="100%">

    <mat-card-header *ngIf="loadOverviewStatus!==1" fxLayout="column" fxLayoutAlign="center end">

      <body class="description mat-small">{{loginText}}:</body>
      {{email}}
    </mat-card-header>
    <!-- title -->
    <mat-card-title class="bafa-display-1" title="Ihre Übersicht über eingereichte Anträge oder Meldungen an das BAFA.
      Es können bisher eingereichte Formulare bearbeitet oder heruntergeladen werden.
      Nutzen Sie die Funktionen unter der Spalte Aktionen.">Übersicht</mat-card-title>

    <!-- toggle table layout -->

    <div fxLayout fxLayoutGap="40px" fxLayout.xs="column" fxLayoutAlign.xs="start start" *ngIf="enableNewAntrag() && !isAdminMode()">


    <button
      mat-flat-button
      class="button"
      type="button"
      color="primary"
      (click)="this.openNewAntragDialog()"
    >
      <mat-icon class="icon">add</mat-icon>
      Neuer Antrag
    </button>
    </div>

    <mat-card-content>

      <!-- table filters -->
      <div fxLayoutAlign="space-between end" fxLayoutGap="20px" fxLayout.xs="column" fxLayoutAlign.xs="center start">



        <div fxLayout="column" fxFlex fxLayoutAlign="space-between stretch" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutAlign.xs="center start"
             style="position: relative; overflow: hidden;">
          <!-- toggle table layout -->
          <div fxFlex fxLayout="column" fxLayoutAlign="center end" *ngIf="showBevollmaechtigterToggle()">
            <div class="description mat-small">Anträge anzeigen als:</div>
            <mat-radio-group fxLayout="row" color="primary" [(ngModel)]="bevollmaechtigterMode"
                             aria-label="Anträge anzeigen als">
              <mat-select [(ngModel)]="bevollmaechtigterMode" (selectionChange)="onOtherChange($event)">
                <mat-option [value]="true" aria-label="Bevollmächtigter">Bevollmächtigte Person</mat-option>
                <mat-option [value]="false" aria-label="Antragsteller">Antragstellende Person</mat-option>
              </mat-select>
            </mat-radio-group>
          </div>

          <div fxLayout fxLayout.xs="column" fxLayoutAlign.xs="center start">
            <!-- search field -->

            <div style="display: block !important" fxLayoutGap="17px" fxLayoutAlign.xs="center start" fxLayout.xs="column">
            <!-- date range -->
              <mat-form-field  appearance="standard"  style="width: 155px !important;">
                <mat-label>Antragsdatum von</mat-label>
                <input matInput [min]="minAntragsdatum" [max]="maxAntragsdatum" [matDatepicker]="fromPicker" [(ngModel)]="dateFrom"
                       (dateChange)="onOtherChange($event)" placeholder="Antragsdatum von">
                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field  appearance="standard"  style="width: 155px !important;">
                <mat-label>Antragsdatum bis</mat-label>
                <input matInput [min]="minAntragsdatum" [max]="maxAntragsdatum" [matDatepicker]="toPicker" [(ngModel)]="dateTo"
                       (dateChange)="onOtherChange($event)" placeholder="Antragsdatum bis">
                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
              </mat-form-field>

            <mat-form-field  appearance="standard" *ngIf="showFilters()" style="width: 170px !important;">
              <mat-label>Vorgangsnummer</mat-label>
              <label>
                <input matInput type="text" placeholder="Suchbegriff (min. 8 Zeichen)" [(ngModel)]="filterVorgangsnummer" (keyup)="applyFilterVorgnagsnummer(true)">
              </label>
              <button *ngIf="filterVorgangsnummer" matSuffix mat-icon-button aria-label="Clear" (click)="deleteFilterVorgangnummer()">
                <mat-icon matTooltip="Filter löschen">close</mat-icon>
              </button>
              <button *ngIf="!filterVorgangsnummer" matSuffix mat-icon-button disabled>
                <mat-icon matTooltip="Tabelle nach Suchbegriff filtern">search</mat-icon>
              </button>
            </mat-form-field>

            <!-- Type select -->
            <mat-form-field appearance="standard" *ngIf="showFilters() && kennzeichenTypeMap?.length > 1" style="width: 100px !important;">
              <mat-label>Verfahren</mat-label>
              <mat-select #verfahrenFilterEvent (selectionChange)="selectionChangeKennzeichen($event.value)">
                <mat-option>(Alle)</mat-option>
                <mat-option *ngFor="let kennzeichen of kennzeichenTypeMap" value="{{kennzeichen.key}}">{{kennzeichen.text}}</mat-option>              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" *ngIf="showFilters() && this.bevollmaechtigterMode" style="width: 170px !important;">
              <mat-label>Antragstellende Person, Organisation</mat-label>
              <label>
                <input matInput type="text" placeholder="Suchbegriff (min. 3 Zeichen)" [(ngModel)]="filterAntragsteller" (click)="deleteFilterStandort()" (keyup)="applyFilterAntragsteller(true)">
              </label>
              <button *ngIf="filterAntragsteller" matSuffix mat-icon-button aria-label="Clear" (click)="deleteFilterAntragsteller()">
                <mat-icon matTooltip="Filter löschen">close</mat-icon>
              </button>
              <button *ngIf="!filterAntragsteller" matSuffix mat-icon-button disabled>
                <mat-icon matTooltip="Tabelle nach Suchbegriff filtern">search</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field appearance="standard" *ngIf="showFilters()" style="width: 170px !important;">
              <mat-label>Standort</mat-label>
              <label>
                <input matInput type="text" placeholder="Suchbegriff (min. 3 Zeichen)" [(ngModel)]="filterStandort" (click)="deleteFilterAntragsteller()" (keyup)="applyFilterStadort(true)">
              </label>
              <button *ngIf="filterStandort" matSuffix mat-icon-button aria-label="Clear" (click)="deleteFilterStandort()">
                <mat-icon matTooltip="Filter löschen">close</mat-icon>
              </button>
              <button *ngIf="!filterStandort" matSuffix mat-icon-button disabled>
                <mat-icon matTooltip="Tabelle nach Suchbegriff filtern">search</mat-icon>
              </button>
            </mat-form-field>

            <!-- Status filter select -->
            <mat-form-field appearance="standard" *ngIf="showFilters()" style="width: 170px !important;">
              <mat-label>Status</mat-label>
              <mat-select #statusFilterEvent (selectionChange)="selectionChange($event.value)">
                <mat-option>(Alle)</mat-option>
                <mat-option *ngFor="let status of statusList" value="{{status.key}}">{{status.description}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- refresh button -->
            <button class="btn-margin" mat-mini-fab *ngIf="showFilters()" color="primary" (click)="this.refreshData()" matTooltip="Tabelleninhalt aktualisieren">
              <mat-icon>refresh</mat-icon>
            </button>

          </div>
        </div>
        </div>
        <!--update vorgangsdaten -->
        <button class="btn-vorschub" mat-mini-fab *ngIf="isAdminMode()" (click)="this.updateVorgangsdaten()"
                color="primary" aria-label="Vorgangsdaten aktualisieren" matTooltip="Vorgangsdaten aktualisieren" >
          <mat-icon>update</mat-icon>
        </button>
      </div>

      <br>
      <!-- table -->
      <div class="overview-table-container">

      <table mat-table [dataSource]="dataSource" matSort [matSortActive]="active" matSortDisableClear [matSortDirection]="direction"
        class="mat-elevation-z3" ngClass.lt-lg="auto-column-width;" (matSortChange)="sortingChange($event)">

        <ng-container matColumnDef="vorgangsnummer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorgangsnummer</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.vorgangsnummer }}">
            <a *ngIf="enableAntragEdit()" [routerLink]="['/antragDetail']"
               [queryParams]="{vorgangsnummer: element.vorgangsnummer, type: element.kennzeichen}">
              {{ element.vorgangsnummer }}</a>
              <span *ngIf="!enableAntragEdit()">{{ element.vorgangsnummer }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="kennzeichen">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Verfahren</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.kennzeichenText }}">{{ element.kennzeichenText }}</td>
        </ng-container>

        <ng-container matColumnDef="antragsdatum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >Antragsdatum</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.antragsdatum | date: dateFormatDe}}">
            {{ element.antragsdatum | date: dateFormatDe}}</td>
        </ng-container>

        <ng-container matColumnDef="standort">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Standort</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.standort }}">{{ element.standort }}</td>
        </ng-container>

        <ng-container matColumnDef="status">

          <th mat-header-cell *matHeaderCellDef>
            <mat-label mat-sort-header>
              <button mat-icon-button color="primary" (click)="this.overViewInfo()" matTooltip="Antrag Status">
                <mat-icon>info</mat-icon>
              </button>
              Status
              </mat-label>
          </th>


          <td mat-cell *matCellDef="let element" class="status-cell" matTooltip="{{ status(element).tooltip }}">
            <a mat-icon-button disabled>
              <mat-icon>{{ status(element).icon }}</mat-icon>
            </a>
            {{ status(element).description }}


          </td>
        </ng-container>

        <ng-container matColumnDef="antragsteller">
          <th class="break-spaces" mat-header-cell *matHeaderCellDef mat-sort-header>Antragstellende Person, Organisation</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.antragsteller }}">{{element.antragsteller}}</td>
        </ng-container>

        <ng-container matColumnDef="links">
          <th mat-header-cell *matHeaderCellDef>Aktionen</th>
          <td mat-cell *matCellDef="let element" class="button-cell">
            <a (click)="showPdf(element)" mat-icon-button [disabled]="isLoading" color="primary" aria-label="Download" matTooltip="Druckquittung herunterladen">
              <mat-icon>save_alt</mat-icon>
            </a>
<!--            <a (click)="openEditWarning()" mat-icon-button color="primary" aria-label="Öffnen" matTooltip="Antrag öffnen"-->
<!--               *ngIf="isEditSuppressed(element)" [disabled]="isAdminMode() || element.stornoWunsch == true">-->
<!--              <mat-icon>edit</mat-icon>-->
<!--            </a>-->
<!--            <a [href]="getEditLinkTarget(element)" target="_blank" mat-icon-button color="primary" aria-label="Öffnen" matTooltip="Antrag öffnen"-->
<!--               *ngIf="isEditAllowed(element)" [disabled]="isAdminMode() || element.stornoWunsch == true">-->
<!--              <mat-icon>edit</mat-icon>-->
<!--            </a>-->

            <div style="display: inline-block !important;" class="my-custom-tooltip" matTooltip="{{!isAdminMode() ? 'Dokumente zum Antrag hochladen' : 'Aktion für den Benutzer ' + email + ' aktiv.'}}" [matTooltipDisabled]="false">
            <a [href]="element.uploadLink" target="_blank" mat-icon-button color="primary" aria-label="Upload" [disabled]="isAdminMode()">
              <mat-icon>publish</mat-icon>
            </a>
            </div>

            <div style="display: inline-block !important;" class="my-custom-tooltip" matTooltip="{{!isAdminMode() ? 'Verwendungsnachweis Druckquittung herunterladen' : !(element.stornoWunsch != null || element.status == 'S' || isLoading) ? 'Verwendungsnachweis Druckquittung herunterladen' : 'Aktion für den Benutzer ' + email + (element.stornoWunsch != null || element.status == 'S' || isLoading ? ' inaktive.' : ' aktiv.')}}" [matTooltipDisabled]="false">
            <a (click)="showVnPdf(element)" mat-icon-button color="primary" aria-label="Download Verwendungsnachweis"
             *ngIf="element.viewVnLink !== null" [disabled]="element.stornoWunsch != null || element.status == 'S' || isLoading">
              <mat-icon>receipt</mat-icon>
            </a>
            </div>

            <div style="display: inline-block !important;" class="my-custom-tooltip" matTooltip="{{!isAdminMode() ? 'Verwendungsnachweis einreichen' : 'Aktion für den Benutzer ' + email + (element.status == 'S' || isLoading ? ' inaktiv.' : ' aktiv.')}}" [matTooltipDisabled]="false">
            <a (click)="getVnLinkTarget(element)" target="_blank" mat-icon-button color="primary" aria-label="Verwendungsnachweis Öffnen"
            *ngIf="element.submitVnLink !== null" [disabled]="isAdminMode() || element.status == 'S' || isLoading">
            <mat-icon>post_add</mat-icon>
            </a>
            </div>

            <div style="display: inline-block !important;" class="my-custom-tooltip" matTooltip="{{!isAdminMode() ? 'Hier können Sie Ihren Antrag stornieren. Die Aktion ist nur vor der Einreichung des Verwendungsnachweises möglich.' : 'Aktion für den Benutzer ' + email +  (element.stornoWunsch != null || element.status == 'S' ? ' inaktiv.' : ' aktiv.')}}" [matTooltipDisabled]="false">
            <a (click)="openStornoAntragDialog(element)" mat-icon-button color="primary" aria-label="Storno"
              *ngIf="(element.kennzeichenText == 'BEG PT' ||
                element.kennzeichenText == 'EBN' || element.kennzeichenText == 'EBW')&& element.status != 'S' && this.isEnabledStorno()
               && (element.stornoWunsch == true || element.stornoWunsch == null)" [disabled]="isAdminMode() || element.stornoWunsch != null || element.status == 'S'">
              <mat-icon>remove_circle_outline</mat-icon>
            </a>
            </div>
            <mat-icon  mat-icon-button color="primary" disabled="false" style="transform: scale(0.7)" *ngIf="element.stornoWunsch == true && this.isEnabledStorno()"
                       matTooltip="Ihr Stornierungswunsch ist eingegangen, Sie erhalten direkt vom BAFA eine Nachricht dazu.">info</mat-icon>

            <div style="display: inline-block !important;" class="my-custom-tooltip" matTooltip="{{resolveDeleteTooltipText(element)}}" [matTooltipDisabled]="false">
            <a (click)="openDeleteAntragDialog(element)" [disabled]="deleteIsDisabled(element)" color="primary" aria-label="Delete antraege" mat-icon-button *ngIf="isAdminMode()">
              <mat-icon *ngIf="!element.timeDeleted" class="center {{account?.deleteRequestDate ? 'color_red' : element.deleteRequestDate ? 'color_orange' : 'primary'}}">delete</mat-icon>
              <mat-icon *ngIf="element.timeDeleted" class="center primary">delete_forever</mat-icon>

            </a>
            </div>
          </td>
        </ng-container>

        <!-- show this when there is no matching data -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Keine Ergebnisse zu Ihrem Suchbegriff "{{ "Vorgangsnummer:" + filterVorgangsnummer + " ; Antragstellende Person:" + filterAntragsteller + " ; Standort:" + filterStandort }}".</td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns() sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
      </table>
      </div>
      <div *ngIf="getLifeSpanInMonth() != null && getLifeSpanInMonth() != 0">
      Hinweis: Anträge, die seit mehr als {{getLifeSpanInMonth()}} Monaten abgeschlossen sind, werden aus Datenschutzgründen automatisch gelöscht.
      </div>
        <mat-paginator #paginator
                     [length]="resultsLength"
                     [pageIndex]="pageIndex"
                     [pageSizeOptions]="[10, 20]"
                     (page)="paginationChange($event)"
                     *ngIf="showPagination()"></mat-paginator>


      <div class="loading-shade" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>

    </mat-card-content>
  </mat-card>
</div>

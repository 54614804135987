import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Moment} from "moment";

export class AntragDeleteData {
  constructor(public deleteRequestDate: Moment,
              public vorgangsnummer: string) {

  }
}
@Component({
  selector: 'confirm-delete-antrag',
  templateUrl: './confirm-delete-antrag.component.html',
})
export class ConfirmDeleteAntragComponent {

  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteAntragComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AntragDeleteData) {
  }

  close(): void {
    this.dialogRef.close('close');
  }

  confirm(): void {
    this.dialogRef.close('confirm');
  }
}
